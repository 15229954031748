.hot-main--script-form {
  @include fonts(#a7a7a7, $fontRobotoRegular, 14);
  line-height: rem(14);
  height: 425px;
  overflow-y: scroll;

  .hot-main--script-form__limit {
    height: 80%;
    overflow-y: scroll;
  }

  .hot-main--script-form__default {
    background-color: white;
    border: 1px solid #b7b7b7;
    border-radius: 4px;
    cursor: pointer;
    @include fonts(#b7b7b7, $fontRobotoRegular, 14);
    height: calc(1.5em + 0.75rem + 2px);
    padding: 5px 12px;
    width: 100%;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .hot-main--script-form__item {
    margin-bottom: 10px;
  }

  .hot-main--script-form__label {
    cursor: pointer;
    @include fonts($colorDefault, $fontRobotoRegular, 14);
    margin-bottom: 5px;
  }

  .hot-main--script-form__label-radio {
    @extend .hot-main--script-form__label;

    &:hover {
      font-weight: 600;
    }

    &:last-child {
      margin-left: 5px;
    }

    .hot-main--script-form__radio {
      margin-right: 5px;
    }
  }

  .hot-main--script-form__input {
    @extend .hot-main--script-form__default;
  }

  .hot-main--script-form__single {
    @extend .hot-main--script-form__default;
  }

  .hot-main--script-form__multi-select {
    width: 100%;

    .rw-widget-container {
      background-color: white;
      border: 1px solid #b7b7b7;
      border-radius: 4px;
      box-shadow: none !important;

      &:hover {
        outline: none;
      }
    }

    .rw-multiselect-tag {
      @include fonts($colorGreyFont, $fontRobotoRegular, 14);
      margin-top: 0;
      vertical-align: middle;
    }

    .rw-popup-transition {
      box-shadow: none !important;
      outline: none !important;
    }

    .rw-btn {
      color: #b7b7b7;
    }
  }

  .hot-main--script-form__radio {
    // -webkit-appearance: none;
  }
}
