.hot-main--reports-upper {
  @include fonts($colorDefault, $fontRobotoRegular, 16);
}

.hot-main--reports_card {
  border-radius: 13px;

  .card-header {
    @include fonts($colorDefault, $fontRobotoMedium, 15);
    border-radius: 13px 13px 0 0;
    padding: 8px 15px;
  }

  .card-body {
    .hot-main--reports_main-result {
      @include fonts($colorGreyFont, $fontRobotoMedium, 45);
    }

    .hot-main--reports_main-comparison {
      @include fonts($colorSuccess, $fontRobotoRegular, 16);

      &.below-med {
        color: $colorBelowRed;
      }
    }

    .hot-main--reports_main-time-frame {
      @include fonts($colorDefault, $fontRobotoRegular, 14);
    }
  }
}

.hot-main--reports-select {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  @include fonts($colorGreyFont, $fontRobotoLight, 15);
  padding: 0 0 3px 0;
  margin: 0 0 0 5px;
}
