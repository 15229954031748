@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot?57dpl');
  src: url('../../fonts/icomoon.eot?57dpl#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?57dpl') format('truetype'),
    url('../../fonts/icomoon.woff?57dpl') format('woff'),
    url('../../fonts/icomoon.svg?57dpl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-no-phone:before {
  content: '\e000';
}
.icon-phone:before {
  content: '\e001';
}
.icon-add-lead:before {
  content: '\e002';
}
.icon-ringcentral:before {
  content: '\e003';
}
.icon-reports:before {
  content: '\e004';
}
.icon-order:before {
  content: '\e005';
}
.icon-order-down:before {
  content: '\e006';
}
.icon-order-up:before {
  content: '\e007';
}
