.hot-main--login {
  height: 100vh;
  display: flex;
  align-items: center;

  .hot-main--login__error {
    @include fonts($colorDanger, $fontRobotoMedium, 12);
    display: block;
    position: relative;
    text-align: center;
    top: -30px;
  }

  .hot-main--login-form__input {
    background-color: transparent;
    border-color: $colorDefault;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid;
    @include fonts(#a0a0a0, $fontRobotoMedium, 14);
    height: 45px;
    line-height: rem(19);
    padding-left: rem(30);
    padding-right: rem(30);
    &:hover,
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .hot-main--login-form__forgot-password {
    @include fonts($colorDefault, $fontRobotoRegular, 12);
    line-height: rem(19);
    text-decoration: underline;

    &:hover {
      color: $colorCloud;
    }
  }

  .hot-ui--btn__cloud {
    background-color: $colorCloud;
    color: white;
    height: 45px;
    line-height: rem(24);
    width: 100%;
  }

  @include placeholder(#a0a0a0, $fontRobotoMedium, 16);
}
