.hot-main--profile-leads {
  background-color: white;
  padding-top: 25px;

  .hot-main--profile-leads-actions {
    .hot-main--profile-leads-actions__fullname {
      @include fonts($colorGreyFont, $fontRobotoRegular, 22);
      line-height: rem(24);
      position: relative;

      @extend .hot-ui--btn-link;

      .hot-ui--btn-link {
        padding: 0 2.5px !important;

        &.phone {
          cursor: pointer;
          display: block;
          @include fonts(#00bc3a, $fontRobotoRegular, 15);
          line-height: rem(18);
          margin: 2.5px 0 0;
          text-decoration: none;
          &:hover {
            color: #ffd619;
          }
          span {
            text-decoration: underline;
          }
        }
      }
    }

    .hot-main--profile-leads-actions__change-groups {
      &.disabled {
        &.sold {
          .input-group-prepend {
            .dropdown-toggle {
              color: $colorSuccess;
            }
          }
        }

        &.dnc {
          .input-group-prepend {
            .dropdown-toggle {
              color: $colorDanger;
            }
          }
        }

        .input-group-prepend {
          .dropdown-toggle {
            border-radius: 22px;
            cursor: default;
            font-size: rem(18);
            padding-left: 195px;
            padding-right: 195px;

            &:after {
              content: none;
            }
          }
        }
      }

      .input-group-prepend {
        .dropdown-toggle {
          background-color: #f2f2f2;
          border-color: #dedede;
          border-radius: 22px 0 0 22px;
          @include fonts(#959595, $fontRobotoRegular, 14);
          margin: 0 !important;
          &::after {
            position: relative;
            top: 2px;
          }
        }

        .dropdown-menu {
          background-color: #f2f2f2;
          border-color: #dedede;

          .dropdown-item {
            &.dnc {
              color: $colorDanger;
            }

            &.sold {
              color: $colorSuccess;
            }

            @include fonts(#959595, $fontRobotoRegular, 14);

            &:focus {
              background-color: #f2f2f2;
              box-shadow: none;
              outline: none;
            }
          }
        }
      }

      .input-group-append {
        @include fonts(#959595, $fontRobotoRegular, 14);
        line-height: rem(24);
        text-align: center;
        display: block;
        margin: 0;
      }

      .form-control {
        border-radius: 0 22px 22px 0;
        height: 40px;
        padding-left: 15px;
        padding-right: 15px;

        .btn-link {
          @include fonts(#a2a2a2, $fontRobotoRegular, 14);
          padding: 0 10px;
          transition: all 0.25s linear;

          &:hover {
            color: $colorCloud;
            text-decoration: underline;
          }
        }
      }
    }

    .hot-main--profile-leads-actions__cta {
      @extend .hot-ui--btn-link;
    }

    .hot-main--profile-leads-actions__time {
      @include fonts(#808080, $fontRobotoLight, 16);
      text-align: right;

      strong {
        font-size: rem(14);
      }
    }
  }

  .hot-main--profile-leads-tabs {
    margin-top: 35px;

    .nav-tabs {
      border: none;

      .nav-link {
        background-color: #f2f2f2;
        border-color: #d0d0d0;
        border-radius: 14px 14px 0 0;
        cursor: pointer;
        @include fonts(#acacac, $fontRobotoRegular, 14);
        line-height: rem(16);
        padding-left: 25px;
        padding-right: 25px;

        &.active {
          cursor: default;
        }

        &:hover,
        &.active {
          background-color: #5f5f5f;
          border-color: #5f5f5f;
          color: white;
        }
      }
    }

    .tab-content {
      background-color: #ffffff;
      border: 1px solid #d0d0d0;
      padding: 0 15px;
      position: relative;
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 3px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.1);
      }

      ::-webkit-scrollbar-thumb {
        cursor: pointer !important;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.9);
      }

      .tab-pane {
        .tab-data {
          display: flex;
          justify-content: flex-start;
          overflow-x: scroll;
          .tab-data__item {
            border-right: 1px solid #d0d0d0;
            display: block;
            margin-right: 21px;
            padding: 5.5px 21px 5.5px 0;
            position: relative;

            &.ico-arrow {
              &:before {
                background-image: url('#{$img}cloudpotato-arrow.png');
                background-size: contain;
                content: '';
                display: block;
                position: absolute;
                right: -10px;
                height: 21px;
                width: 21px;
              }

              &:last-child {
                &:before {
                  display: none;
                }
              }
            }

            .tab-data__item-title {
              @include fonts($colorGreyFont, $fontRobotoRegular, 13);

              .date {
                font-family: $fontRobotoMedium;
              }
            }

            .tab-data__item-description {
              @include fonts($colorGreyFont, $fontRobotoRegular, 13);
            }
          }
        }
      }
    }
  }

  .hot-main--profile-leads-container {
    padding-bottom: 15px;
    margin-top: 25px;

    .hot-main--profile-leads-container__aside {
      border: 1px solid #d0d0d0;
      border-radius: 26px 26px 0 0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-top: 35px;
      padding: 25px 30px;

      .hot-main--profile-leads-notes__title {
        @include fonts(#a7a7a7, $fontRobotoRegular, 20);
        line-height: rem(22);
        margin-bottom: 15px;
      }

      .hot-main--profile-leads-notes {
        flex-grow: 1;
        height: 0px;
        overflow-y: scroll;
        margin-bottom: 15px;

        .hot-main--profile-leads-notes-item {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          margin-bottom: 15px;
          padding-bottom: 15px;

          &:last-child {
            border-bottom: none;
          }

          .hot-main--profile-leads-notes__content {
            @include fonts(#a7a7a7, $fontRobotoRegular, 15);
            line-height: rem(18);
          }

          .hot-main--profile-leads-notes__date {
            @include fonts(#a7a7a7, $fontRobotoBold, 14);
            line-height: rem(16);
          }
        }
      }

      .hot-main--profile-leads-notes-form {
        background-color: transparent;
        border: 1px solid #d0d0d0;
        border-radius: 19px;
        margin-bottom: 10px;
        padding: 15px;
        position: relative;

        textarea {
          background-color: transparent;
          border: none;
          @include fonts(#a7a7a7, $fontRobotoRegular, 15);
          height: 40px;
          resize: none;
          width: 100%;
        }

        button {
          background-color: transparent;
          border: 1px solid #d0d0d0;
          border-radius: 15px;
          bottom: 15px;
          @include fonts(#a7a7a7, $fontRobotoRegular, 12);
          padding: 1px 25px;
          position: absolute;
          right: 15px;

          &:hover {
            background-color: #a7a7a7;
            border-color: #a7a7a7;
            color: white;
          }
        }
      }
    }

    .hot-main--profile-leads-container__nav {
      display: flex;
      justify-content: flex-start;

      .hot-main--profile-leads-container__nav-item {
        display: block;

        &.tab {
          border: 1px solid #d0d0d0;
          border-bottom: none;
          border-radius: 14px 14px 0 0;
          @include fonts(#acacac, $fontRobotoBold, 15.5);
          padding: 8px 25px;
        }

        .btn-link {
          @include fonts(#73bde3, $fontRobotoRegular, 14);
          text-decoration: underline;

          &:hover {
            color: $colorDanger;
          }
        }
      }
    }

    .hot-main--profile-leads-container__information {
      border: 1px solid #d0d0d0;
      border-radius: 0 26px 0 0;
      @include fonts(#a7a7a7, $fontRobotoRegular, 14);
      line-height: rem(21);
      min-height: 400px;
      padding: 15px 25px 20px;

      a,
      p,
      ul li {
        @include fonts(#a7a7a7, $fontRobotoRegular, 14);
        line-height: rem(24);
      }

      p {
        margin-bottom: 12px;
        margin-top: 8px;
      }

      .hot-lead--input-search {
        display: inline-block;

        div input {
          background-color: white;
          border: 1px solid #b7b7b7;
          border-radius: 4px;
          @include fonts(#b7b7b7, $fontRobotoRegular, 14);
          line-height: rem(18);
          margin-top: 0;
          margin-right: 5px;
          width: 600px;
          padding: 10px 25px;

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }

        @include placeholder(#b7b7b7, $fontRobotoRegular, 14);
      }

      .btn-search {
        background-color: white;
        border: 1px solid #b7b7b7;
        border-radius: 50%;
        color: #b7b7b7;
        font-size: 25px;
        padding: 5px 10.5px;

        &:focus,
        &:hover {
          background-color: #b7b7b7;
          color: white;
          text-decoration: none;
        }
      }
    }

    .nav-tabs {
      border: none;

      .nav-link {
        background-color: white;
        border-color: #d0d0d0;
        border-radius: 14px 14px 0 0;
        cursor: pointer;
        @include fonts(#acacac, $fontRobotoRegular, 14);
        line-height: rem(18);
        padding: 8px 25px;

        &.active {
          cursor: default;
        }

        &:hover,
        &.active {
          background-color: #5f5f5f;
          border-color: #5f5f5f;
          color: white;
        }
      }
    }

    .main--tabs__content {
      border: 1px solid #d0d0d0;
      border-radius: 0 26px 0 0;
      min-height: 400px;
      padding: 15px 25px 20px;
      .main--tabs__content-default {
        @include fonts(#707070, $fontRobotoRegular, 14);
      }
    }
  }

  .hot-main--profile-leads-edit {
    margin-top: 25px;

    &:before {
      border-top: 1px solid rgba(183, 183, 183, 0.25);
      content: '';
      display: block;
      margin: 25px 0 15px;
      width: 100%;
    }

    @extend .hot-main--form-profile-leads;
  }
}

.white-link {
  background-color: white;
  border-color: #00bc3a;
  color: #00bc3a;
}

.modal-body {
  @include fonts($colorGreyFont, $fontRobotoRegular, 14);
}

.modal-title {
  align-content: center;
  @include fonts($colorGreyFont, $fontRobotoRegular, 28);
}

.multi-select-wrapper {
  border-radius: 4px;
  position: relative;
  cursor: text;
}
