$colorDanger: #c1272d;
$colorDefault: #9d9d9d;
$colorPrimary: #40aaed;
$colorSuccess: #00bc3a;
$colorGreyFont: #707070;
$colorBelowRed: #ff6767;
$colorCloud: #034b63;
$colorBlueLink: #73c7d2;
$colorWarning: #ffd619;

$fontRobotoThin: 'Roboto-Thin';
$fontRobotoLight: 'Roboto-Light';
$fontRobotoBlack: 'Roboto-Black';
$fontRobotoRegular: 'Roboto-Regular';
$fontRobotoMedium: 'Roboto-Medium';
$fontRobotoBold: 'Roboto-Bold';

$img: '../../images/';
$imgBg: '../../images/backgrounds/';
