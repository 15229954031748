::-webkit-scrollbar {
  // display: none;
}

* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  outline: 0;
  text-rendering: optimizeLegibility;
  text-decoration: none;
}

html {
  background-color: #f5f5f5;
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  font-family: $fontRobotoRegular;
  font-size: 16px;
}

main,
#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.border-line-bottom-white {
  border-bottom: 1px solid white;
}

.border-line-left-white {
  border-left: 1px solid white;
}

.border-line-right-white {
  border-right: 1px solid white;
}

.border-line-top-white {
  border-right: 1px solid white;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.none {
  display: none;
  opacity: 0;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.opacity-0 {
  opacity: 0;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-15 {
  opacity: 0.15;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-50 {
  opacity: 0.5;
}

.overflow {
  overflow: hidden;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-15 {
  padding-right: 15px;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-top-30 {
  padding-top: 30px;
}

.position-fixed {
  left: 0;
  position: fixed;
  width: 100%;
}

a {
  color: #40aaed;
  transition: all 0.15s ease-in-out;

  &:hover {
    color: #333333;
  }
}

.btn {
  border-radius: 45px;
  font-family: $fontRobotoMedium;
  font-size: rem(14);
  line-height: rem(24);
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
  transition: all 0.7s ease-in-out;

  &:focus {
    box-shadow: none !important;
  }
}

.tooltip {
  &.bs-tooltip-right .arrow:before {
    border-right-color: #5f5f5f;
  }
  &.bs-tooltip-left .arrow:before {
    border-left-color: #5f5f5f;
  }
  &.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #5f5f5f;
  }
  &.bs-tooltip-top .arrow:before {
    border-top-color: #5f5f5f;
  }

  .tooltip-inner {
    background-color: #5f5f5f;
    @include fonts(white, $fontRobotoBold, 13.5);
  }
}
