.hot-ui--datepicker__time {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  @include fonts($colorGreyFont, $fontRobotoLight, 15);
  padding: 5px 10px;
  width: 60%;
  text-align: center;
}

.hot-ui--datepicker {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  @include fonts($colorGreyFont, $fontRobotoLight, 12);
  padding: 8px 10px;
  width: 100%;
  text-align: left;
}

.react-datepicker-popper {
  left: 17% !important;
  position: absolute;
  transform: none !important;
  top: 74px !important;
}

.react-datepicker-manager,
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container {
  display: flex !important;
  justify-content: center;
}

.react-datepicker {
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation {
    &:focus {
      outline: none;
    }
    &.react-datepicker__navigation--next {
      right: 127px;
    }
  }
  .react-datepicker__month-container {
    .react-datepicker__header {
      background: transparent;
      border: none;
      .react-datepicker__current-month {
        @include fonts($colorGreyFont, $fontRobotoLight, 14);
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          @include fonts($colorGreyFont, $fontRobotoMedium, 12);
        }
      }
      .react-datepicker__current-month--hasMonthDropdown {
        display: none !important;
      }
      .react-datepicker__year-dropdown-container,
      .react-datepicker__month-dropdown-container {
        select {
          border: none;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          @include fonts($colorGreyFont, $fontRobotoLight, 12);
          padding: 0 0 3px 0;
          margin: 0 0 0 5px;
        }
      }
    }

    .react-datepicker__month {
      .react-datepicker__day {
        @include fonts(black, $fontRobotoLight, 12);
        &.react-datepicker__day--selected {
          @include fonts(white, $fontRobotoRegular, 12);
          background-color: $colorPrimary;
          border-radius: 50%;
        }
        &.react-datepicker__day--keyboard-selected {
          @include fonts(white, $fontRobotoRegular, 12);
          background-color: $colorDefault;
          border-radius: 50%;
        }
      }
      .react-datepicker__day--disabled {
        @include fonts(#c9c9c9, $fontRobotoRegular, 12);
      }
    }
  }
  .react-datepicker__time-container {
    width: 120px;
    .react-datepicker__header {
      background: transparent;
      border: none;
    }
    .react-datepicker__header--time {
      .react-datepicker-time__header {
        @include fonts($colorGreyFont, $fontRobotoLight, 14);
      }
    }
    .react-datepicker__time {
      .react-datepicker__time-box {
        width: 100%;
        .react-datepicker__time-list {
          .react-datepicker__time-list-item {
            @include fonts(black, $fontRobotoLight, 12);
            &.react-datepicker__time-list-item--selected {
              @include fonts(white, $fontRobotoRegular, 12);
              background-color: $colorPrimary;
            }
          }
        }
      }
    }
  }
}
