.hot-main--dashboard {
  margin-top: -55px;
  .hot-main--dashboard-welcome {
    h1 {
      @include fonts($colorCloud, $fontRobotoLight, 36);
      line-height: rem(44);
      margin: 0 0 15px;
    }

    p {
      @include fonts($colorDefault, $fontRobotoLight, 18);
      line-height: rem(22);
      margin: 0;
    }
  }

  .hot-main--dashboard-clients {
    .hot-main--dashboard-clients__item {
      border-left: 1px solid #c7c7c7;
      display: flex;
      align-items: center;
      padding: 10px 5px;

      &:first-child {
        border-left: none;
      }

      img {
        cursor: pointer;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        transition: all 0.1s linear;
        opacity: 0.3;

        &:hover {
          animation: pulse 0.9s;
          filter: none;
          -webkit-filter: grayscale(0);
          opacity: 1;
        }
      }
    }
    &--loading {
      font-size: rem(22);
      padding-top: rem(44);
    }
  }
}

.hot-main--dashboard-leads {
  background-color: white;

  &--loading {
    font-size: rem(80);
  }
}

.hot-main--dashboard-call-indicator {
  background-color: #00bc3a;
  text-align: center;

  &.muted {
    background-color: #ff8547;
  }

  .hot-main--dashboard-call-indicator__title {
    @include fonts(white, $fontRobotoRegular, 16);
    padding: 7px 10px;
  }

  .hot-main--dashboard-call-indicator__cta {
    @extend .hot-ui--btn-link;
    .btn {
      font-size: rem(14) !important;
    }
  }
}

.invalid-feedback {
  display: block !important;
}
