/* UI BUTTONS */
.hot-ui--btn__default {
  background-color: transparent;
  border-color: $colorDefault;
  color: $colorDefault;

  &:hover {
    background-color: $colorDefault;
    border-color: $colorDefault;
    color: white;
  }
}

.hot-ui--btn__danger {
  background-color: transparent;
  border-color: $colorDanger;
  color: $colorDanger;

  &:hover {
    background-color: $colorDanger;
    border-color: $colorDanger;
    color: white;
  }
}

.hot-ui--btn__cloud {
  background-color: transparent;
  border-color: $colorCloud;
  color: $colorCloud;

  &:hover {
    background-color: $colorCloud;
    border-color: $colorCloud;
    color: white;
  }
}

.hot-ui--btn__success {
  background-color: transparent;
  border-color: $colorSuccess;
  color: $colorSuccess;

  &:hover {
    background-color: $colorSuccess;
    border-color: $colorSuccess;
    color: white;
  }
}

.hot-ui--btn__primary {
  background-color: transparent;
  border-color: $colorPrimary;

  &:hover {
    background-color: $colorPrimary;
    border-color: $colorPrimary;
    color: white;
  }
}
/* END UI BUTTONS */

/* UI IMAGES */
.hot-ui-logo__5 {
  img {
    width: 5%;
  }
}

.hot-ui-logo__10 {
  img {
    width: 10%;
  }
}

.hot-ui-logo__15 {
  img {
    width: 15%;
  }
}

.hot-ui-logo__25 {
  img {
    width: 25%;
  }
}

.hot-ui-logo__30 {
  img {
    width: 30%;
  }
}

.hot-ui-logo__40 {
  img {
    width: 40%;
  }
}

.hot-ui-logo__50 {
  img {
    width: 50%;
  }
}

.hot-ui-logo__60 {
  img {
    width: 60%;
  }
}

.hot-ui-logo__70 {
  img {
    width: 70%;
  }
}

.hot-ui-logo__75 {
  img {
    width: 75%;
  }
}

.hot-ui-logo__90 {
  img {
    width: 90%;
  }
}

.hot-ui-logo__100 {
  img {
    width: 100%;
  }
}

.hot-ui-logo__110 {
  img {
    width: 110%;
  }
}

.hot-ui-logo__120 {
  img {
    width: 120%;
  }
}
/* END UI IMAGES */

/* UI TABLES */
.hot-ui--table {
  thead {
    th {
      @include fonts($colorGreyFont, $fontRobotoBold, 12);
      line-height: rem(15);
    }
  }

  tbody {
    tr {
      &.table-dnc {
        background-color: #fff7ef;

        .group-name {
          color: $colorDanger;
        }
      }

      &.table-sold {
        background-color: #f0fff9;

        .group-name {
          color: $colorSuccess;
        }
      }
    }

    td {
      color: $colorGreyFont;
      font-size: rem(12);
      line-height: rem(15);

      &.calling {
        background-color: $colorSuccess;
        color: white;
      }

      div {
        &.notes-content {
          overflow: hidden;
          text-overflow: ellipsis;
          width: 200px;
          white-space: nowrap;
        }
      }

      button {
        @include fonts($colorCloud, $fontRobotoLight, 12);
        line-height: rem(17);
        padding: 0;
        text-decoration: underline;

        &.notes {
          right: 0;
        }

        &:hover {
          color: $colorPrimary;
        }
      }
    }
  }

  .hot-ui--table__short {
    font-size: rem(12);
    padding-left: 5px;
    .icon-order-down,
    .icon-order-up {
      position: relative;
      font-size: rem(8);
    }
  }
}
/* END UI TABLES */

/* UI MODAL */
.hot-ui--modal-container {
  &.custom-py-5 {
    .modal-content {
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }
  &.custom-py-4 {
    .modal-content {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  .modal-content {
    background-color: #f5f5f5;
    border-radius: 10px;
    border-color: #f5f5f5;

    .modal-body {
      h3,
      h4 {
        @include fonts($colorDefault, $fontRobotoMedium, 19);
        line-height: rem(21);
      }
      h5 {
        @include fonts($colorDefault, $fontRobotoMedium, 17);
        line-height: rem(21);
      }

      p {
        @include fonts(#a0a0a0, $fontRobotoRegular, 14);
        line-height: rem(18);

        a {
          color: $colorDefault;
          text-decoration: underline;

          &:hover {
            color: $colorDanger;
          }
        }
      }

      textarea {
        border: 1px solid #b7b7b7;
        border-radius: 4px;
        cursor: pointer;
        @include fonts(#a0a0a0, $fontRobotoRegular, 14);
        height: 150px;
        padding: 15px;
        resize: none;
        width: 100%;
      }
    }

    .modal-close {
      background-color: transparent;
      border: none;
      color: $colorDefault;
      font-size: 19px;
      padding: 0;
      position: absolute;
      right: 15px;
      top: 5px;
      z-index: 1;

      &:hover {
        color: #5f5f5f;
      }
    }

    .modal-footer {
      border-top: none;
      padding-top: 0;
    }
  }

  .hot-ui--modal__info {
    @include fonts(#a0a0a0, $fontRobotoRegular, 16);
    line-height: rem(20);

    span {
      font-size: rem(30);
      line-height: rem(37);
      margin-bottom: 15px;
      &.subtitles {
        font-size: rem(16);
        line-height: rem(18);
        margin-bottom: 10px;
      }
    }
  }

  .hot-ui--modal__actions-cta {
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: inline-block;
    height: 60px;
    margin: 0 10px;
    max-width: 60px;
    min-width: 60px;
    vertical-align: top;

    &.in {
      border: none;
      background-image: url('#{$img}cloudpotato-incoming-call.png');
    }

    &.out {
      background-image: url('#{$img}cloudpotato-call.png');
      background-size: 40%;
      border: 1px solid #b7b7b7;
      border-radius: 50%;
    }
  }
}
/* END UI MODAL */

/* UI TOOLTIP */
.hot-ui--note-tooltip {
  .tooltip {
    backdrop-filter: blur(4px);
  }
  .tooltip-inner {
    background-color: #f5f5f5d0;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    border-radius: 13px;
    @include fonts($colorGreyFont, $fontRobotoLight, 15);
    line-height: rem(18);
    max-width: 700px;
    max-height: 500px;
    overflow-y: scroll;
    padding: 20px 40px;
    text-align: justify;
    width: 500px;

    .hot-ui--note-tooltip__content {
      margin-top: 10px;
      h6,
      p {
        font-size: rem(14);
        line-height: rem(18);
      }

      &:after {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        content: '';
        display: block;
        height: 1px;
        margin: auto;
        margin-top: 25px;
        margin-bottom: 25px;
        width: 100%;
      }
      &:last-child::after {
        border: none !important;
      }
    }
  }

  .arrow {
    display: none !important;
  }
}
/* END UI TOOLTIP */

/* UI LOADER */
// .global-layer {
//   background-color: rgba(0, 0, 0, 0.1);
//   filter: grayscale(100%);
//   height: 100vh;
//   position: absolute;
//   width: 100vw;
// }

// .hot-ui--loader-global {
//   left: 0;
//   max-height: 140vh;
//   overflow: hidden;
//   position: fixed;
//   scroll-behavior: unset;
//   top: 0;
//   width: 100vw;
// }

.hot-ui--loader-container {
  position: relative;
  width: 100%;

  &.main {
    font-size: rem(44);
    height: 100vh;
  }

  .hot-ui--loader {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;

    &:after {
      animation: hot-ui--loader-back 1.5s linear infinite;
      border: medium solid $colorCloud;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-radius: 50%;
      content: ' ';
      display: inline-block;
      height: 2em;
      width: 2em;
      position: absolute;
    }

    &:before {
      animation: hot-ui--loader 1.5s linear infinite;
      border: medium solid $colorDefault;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      border-radius: 50%;
      content: ' ';
      display: inline-block;
      height: 2em;
      width: 2em;
      position: absolute;
    }
  }
}
/* END UI LOADER */

.hot-ui--format-date {
  cursor: default;
}

.hot-ui--select-date {
  border: none;
  @include fonts($colorGreyFont, $fontRobotoLight, 16);
  margin: 0 10px 0 0;
}

.hot-ui--date-time {
  margin: 25px 0 10px;
  .react-datetime-picker__wrapper {
    border-radius: 15px;
    padding: 0 15px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    .react-datetime-picker__inputGroup {
      input,
      select {
        @include fonts($colorGreyFont, $fontRobotoLight, 16);
      }
    }
    .react-datetime-picker__calendar-button {
      svg {
        font-size: 18px;
        color: #afafaf;
      }
    }
  }
}

.time {
  display: flex;
  flex-direction: column;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../../images/cloudpotato-down-arrow.svg) no-repeat 95% center;
}

.form-control {
  font-size: rem(14);
  line-height: rem(18);
}

.main-typehead {
  .dropdown-item {
    font-size: rem(14);
    line-height: rem(18);
    color: #495057 !important;
  }
}
