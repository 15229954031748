.hot-main--footer {
  background-color: #F2F2F2;
  bottom: 0;
  left: 0;
  position: sticky;
  text-align: center;
  width: 100%;

  .hot-main--footer-groups {
    display: flex;
    justify-content: flex-start;

    .hot-main--footer-group {
      display: block;

      .hot-main--footer-group__item {
        background-color: #E1E1E1;
        border-radius: 20px 20px 0 0;
        cursor: pointer;
        display: block;
        margin: 0 1.25px 2.5px;
        padding: 12.5px 25px;
        transition: all .10s ease-out;

        &:hover {
          background-color: #FFFFFF;
          span { color: $colorGreyFont; }
        }

        &.active {
          background-color: #FFFFFF;

          span {
            @include fonts($colorGreyFont, $fontRobotoBold, 15);
            line-height: rem(18);
          }
        }

        span {
          @include fonts(#A2A2A2, $fontRobotoRegular, 16);
          line-height: rem(19);
          transition: all .15s ease-in-out;
        }
      }

      .hot-main--footer-subgroup {
        display: flex;

        .hot-main--footer-subgroup__item {
          background-color: #E1E1E1;
          border: 1px solid #D4D4D4;
          border-left: none;
          cursor: pointer;
          display: block;
          padding: 10px 20px;
          transition: all .10s ease-in;

          &:hover {
            background-color: #FFFFFF;
            span { color: $colorGreyFont; }
          }

          &:first-child  {
            border-left: 1px solid #D4D4D4;
            margin-left: 2.5px;
          }

          &:last-child {
            margin-right: 2.5px;
          }

          &.active {
            background-color: #FFFFFF;
            span { color: $colorGreyFont; }
          }

          span {
            @include fonts(#A2A2A2, $fontRobotoRegular, 11);
            line-height: rem(13);
            transition: all .15s ease-in-out;
          }
        }
      }
    }
  }
}
