.hot-main--header-lead {
  background-color: #f5f5f5;
  padding-bottom: 30px;
  padding-top: 30px;

  @extend .hot-main--header-profile;

  .hot-main--header-actions__cta {
    background-color: transparent;
    border: 1px solid $colorDefault;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    line-height: rem(30);
    margin: 0 3px;
    max-width: 30px;
    min-width: 30px;
    text-align: center;
    transition: all 0.45s ease-in-out;
    vertical-align: top;

    &:first-child {
      margin-left: 1.2rem;
    }

    &:hover {
      border-color: $colorCloud;
      text-decoration: none;
      svg,
      i {
        animation: pulse 0.5s;
        color: $colorCloud;
      }
    }

    svg,
    i {
      color: $colorDefault;
      font-size: rem(16);
      transition: all 0.25s ease-in-out;
    }

    &.btn-rc {
      border-color: $colorSuccess;

      svg,
      i {
        color: $colorSuccess;
      }

      &:hover {
        border-color: $colorWarning;

        svg,
        i {
          animation: none;
          color: $colorWarning;
        }
      }
    }
    &.btn-phone {
      line-height: rem(34);
      i {
        position: relative;
        left: -1px;
      }
    }
  }

  .hot-main--header-search {
    border: 1px solid #d8d8d8;
    border-radius: 27px;
    @include fonts(#808080, $fontRobotoRegular, 12);
    height: 31px;
    line-height: rem(18);
    margin-left: 20px;
    padding-left: 15px;
    padding-right: 15px;
    width: 58%;
  }

  .hot-main--header-actions__switch {
    .dropdown-toggle {
      background-color: transparent;
      border: none;
      @include fonts(#808080, $fontRobotoRegular, 16);

      &:hover,
      &:focus {
        background-color: transparent;
        border: none;
      }
    }

    .dropdown-menu {
      min-width: 12rem;

      .dropdown-item {
        align-items: flex-end;
        display: flex;
        margin-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
        justify-content: flex-start;
        transition: all 0.4s ease;

        &:hover {
          .switch-img img {
            opacity: 1;
          }

          .switch-client {
            border-left-color: #808080;
            color: $colorDanger;
          }
        }

        &:focus {
          background-color: transparent;
          outline: none;
        }

        .switch-img {
          margin-right: 10px;
          width: 35%;

          img {
            opacity: 0.7;
          }
        }

        .switch-client {
          border-left: 1px solid #808080;
          @include fonts(#808080, $fontRobotoRegular, 14);
          padding-left: 10px;
        }
      }
    }
  }
}

.hot-main--header-dashboard {
  padding-top: 15px;

  @extend .hot-main--header-profile;
}
