@import '~bootstrap/scss/bootstrap';
@import '~react-widgets/dist/css/react-widgets.css';
@import '~react-datepicker/dist/react-datepicker.css';

// CONFIG
@import 'modules/_vars.scss';

// CONFIG MODULES
@import 'modules/_position.scss';
@import 'modules/_normalize.scss';
@import 'modules/_typography.scss';
@import 'modules/_icomoon.scss';
@import 'modules/_app.scss';
@import 'modules/_animated.scss';

// COMPONENTS
@import 'components/_ui.scss';
@import 'components/_utils.scss';
@import 'components/_date-picker.scss';
@import 'components/_login.scss';
@import 'components/_header.scss';
@import 'components/_main.scss';
@import 'components/_leads.scss';
@import 'components/_scripts.scss';
@import 'components/_reports.scss';
@import 'components/_footer.scss';
