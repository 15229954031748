@font-face {
  font-family: 'Roboto-Thin';
  src: url('../../fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Thin.woff') format('woff'),
    url('../../fonts/Roboto-Thin.ttf')  format('truetype'),
    url('../../fonts/Roboto-Thin.svg#Roboto-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Light.woff') format('woff'),
    url('../../fonts/Roboto-Light.ttf')  format('truetype'),
    url('../../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('../../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Black.woff') format('woff'),
    url('../../fonts/Roboto-Black.ttf')  format('truetype'),
    url('../../fonts/Roboto-Black.svg#Roboto-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Regular.woff') format('woff'),
    url('../../fonts/Roboto-Regular.ttf')  format('truetype'),
    url('../../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Medium.woff') format('woff'),
    url('../../fonts/Roboto-Medium.ttf')  format('truetype'),
    url('../../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Bold.woff') format('woff'),
    url('../../fonts/Roboto-Bold.ttf')  format('truetype'),
    url('../../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
