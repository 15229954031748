.hot-main--header-profile {
  .hot-main--header-profile__date {
    @include fonts(#808080, $fontRobotoRegular, 12);
    line-height: rem(27);
  }

  .hot-main--header-profile__btn {
    background-color: transparent;
    border-color: $colorPrimary;
    color: $colorPrimary;
    margin-top: 8px;
    padding: 0 28px;

    &:hover {
      background-color: $colorPrimary;
      border-color: $colorPrimary;
      color: white;
    }
  }

  .hot-main--header-profile__user {
    .dropdown-toggle {
      &:after {
        color: transparent;
        position: relative;
        top: 2px;
        background: url('../../images/cloudpotato-down-arrow.svg') no-repeat
          center;
        width: 12px;
        height: 10px;
        margin-left: 4px;
      }
    }
    span {
      @include fonts(#808080, $fontRobotoRegular, 12);
      line-height: rem(18);
      padding-left: 5px;
    }
  }

  .hot-ui--btn {
    margin-top: 3px;
    padding: 0 20px;

    &.start {
      background-color: transparent;
      border-color: $colorSuccess;
      color: $colorSuccess;
      font-family: $fontRobotoRegular;
      font-size: rem(12);

      &:hover {
        background-color: $colorSuccess;
        border-color: $colorSuccess;
        color: white;
      }
    }

    &.stop {
      background-color: transparent;
      border-color: $colorDanger;
      color: $colorDanger;

      &:hover {
        background-color: $colorDanger;
        border-color: $colorDanger;
        color: white;
      }
    }
  }

  .dropdown-menu {
    background-color: #f5f5f5;
    padding: 4px 0;
    margin-top: 0;
    top: -5px !important;

    .dropdown-divider {
      border-top-color: rgba(0, 0, 0, 0.15);
      margin: 2px 0;
    }

    .dropdown-item {
      color: #808080;
      font-family: $fontRobotoLight;
      font-size: rem(14);

      &:hover {
        background-color: transparent;
        color: $colorCloud;
      }

      &:focus {
        background-color: transparent;
        outline: none;
      }
    }
  }

  .nav {
    justify-content: flex-end;
  }
}

.hot-main--form-profile-leads {
  .hot-main--form-profile-leads_label {
    cursor: pointer;
    @include fonts($colorDefault, $fontRobotoRegular, 14);
    margin-bottom: 0;
  }

  .hot-main--form-profile-leads_input {
    background-color: white;
    border: 1px solid #b7b7b7;
    border-radius: 4px;
    cursor: pointer;
    @include fonts(#b7b7b7, $fontRobotoRegular, 14);

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .DayPickerInput {
    display: block;

    input {
      background-color: white;
      border: 1px solid #b7b7b7;
      border-radius: 4px;
      @include fonts(#b7b7b7, $fontRobotoRegular, 14);
      cursor: pointer;
      display: block;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      width: 100%;
    }
  }

  .dropdown-toggle {
    background-color: #f2f2f2;
    border-color: #dedede;
    cursor: pointer;
    @include fonts(#959595, $fontRobotoRegular, 14);
  }

  @include placeholder(#b7b7b7, $fontRobotoRegular, 14);
}

.hot-ui--btn-link {
  button.btn-link {
    &.cta-call {
      color: white;

      &:hover {
        font-family: $fontRobotoMedium;
        color: white;
      }
    }

    @include fonts($colorCloud, $fontRobotoRegular, 15);
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: underline;

    &:hover {
      color: $colorPrimary;
    }
  }
}

.hot-ui--select__timezone {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  cursor: pointer;
  @include fonts($colorGreyFont, $fontRobotoLight, 15);
  padding: 8px 10px;
  width: 70%;
}
